.about {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    // gap: 10rem;
    // margin: 0 4rem 0 4rem;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    @include media ('<=phone') {
        height: auto;
        padding: 2rem 0;
        flex-direction: column;
        gap: 0.8rem;
        align-items: flex-start;
        margin: 0.5rem 0 0 2rem;
    }
    img {
        width: 25vw;
        @include media ('<=phone') {
            width: 67vw;
            padding-bottom: 1.7rem;
            // order: 2;
        }
    }
    // .text {
    //     display: flex;
    //     flex-direction: row;
    //     align-items: center;
    //     gap: 10rem;
    .t1 {
        font-family: 'Aileron', sans-serif;
        font-style: italic;
        line-height: 148%;
        font-size: 0.77rem;
        color: $yellow;
        max-width: 45ch;
        @include media ('<=phone') {
            // order: 1;
            font-size: 0.7rem;
            max-width: 80vw;
        }
    }
    .t2 {
        font-family: 'Aileron', sans-serif;
        line-height: 148%;
        font-size: 0.77rem;
        color: $white;
        max-width: 47ch;
        @include media ('<=phone') {
            font-size: 0.7rem;
            line-height: 138%;
            max-width: 80vw;
            // order: 3;
        }
        .links {
            display: flex;
            flex-direction: row;
            gap: 0.85rem;
            color: $grey;
        }
    }
    // }
}