.landing {
    height: calc(var(--vh, 1vh) * 100);
    background-image: url("../../static/images/main/l2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    .body-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        vertical-align: middle;
        gap: 1rem;
        @include media ('<=phone') {
            // height: 100vh;
            align-items: flex-start;
            align-content: flex-end;
            // justify-content: flex-end;
            // margin-bottom: 30vh;
            gap: 50vh;
        }
        .heading {
            font-family: "Archivo Black";
            font-weight: 400;
            font-size: clamp(4.5rem, calc(7vw + 1rem), 12rem);
            text-transform: uppercase;
            color: $red;
            line-height: 1.1;
            @include media ('<=phone') {
                width: 90vw;
            }
        }
        .menu-links {
            font-family: "Aileron Semibold";
            font-size: 1.3rem;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            color: $red;
            flex-wrap: wrap;
            gap: 2rem;
            width: 100%;
            // .category {
            //     pointer-events: none;
            //     transition: transform 0.1s linear;
            // }
            // .category:hover~.category {
            //     transform: translate(-50%, -50%) scale(8);
            // }
            .category2 {
                color: $yellow;
            }
            @include media ('<=phone') {
                font-size: 1rem;
                width: 75vw;
            }
        }
    }
}