.portfolio {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @include media("<=phone") {
    gap: 1.5rem;
  }
  .heading {
    //layout
    margin-left: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.3rem;
    @include media("<=phone") {
      margin-left: 1rem;
      gap: 0.5rem;
    }
    .title {
      //typography
      color: $red;
      font-family: "Archivo Black";
      font-size: 4.375rem;
      font-weight: 400;
      text-transform: uppercase;
      @include media("<=phone") {
        font-size: 3rem;
        // position: fixed;
        // background-color: $black;
        // height: 20vh;
        // width: 100vw;
        // padding: 1rem 0;
      }
    }
    .description {
      //typography
      color: $white;
      font-family: "Courier Prime";
      font-size: 0.74rem;
      font-weight: 400;
      line-height: 140%;
      /* 17.76px */
      //layout
      max-width: 80ch;
      @include media("<=phone") {
        font-size: 0.61rem;
        width: 90vw;
      }
    }
  }
  .img-container {
    display: flex;
    align-items: flex-end;
    gap: 1.875rem;
    overflow-x: scroll;
    flex-direction: row;
    @include media("<=phone") {
      // overflow-x: auto;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    }
    img {
      // height: 520px;
      height: 70vh;
      &:first-child {
        margin-left: 2.5rem;
      }
      @include media("<=phone") {
        height: auto;
        width: 95vw;
        &:first-child {
          margin-left: initial;
        }
      }
    }
    .copyright {
      font-weight: bold;
      color: $grey;
      font-size: 0.74rem;
      font-weight: 400;
      line-height: 140%;
      padding: 0 7rem;
      flex-shrink: 0;
      @include media("<=phone") {
        padding: 1rem 2rem;
      }
    }
  }
}
